import { useDispatch, useSelector } from "react-redux";

import SendDataToHID from "./webhid/sendData";
import TabNavigation from "./components/TabNavigation";
import { useEffect, useState } from "react";
import StatusBar from "./StatusBar";

import {
  blurMainPage,
  blurMainPageAction,
  changeAppLanguage,
  connectedDeviceAction,
  popupConfigureUIAction,
  sendAlertHintsAction,
  settingSetGameDisableList,
  settingSetRadioChoice,
  userCheckUpdateAction,
  userResetToFactoryAction,
} from "./webhid/action";

import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { gameDisable } from "./SettingData";
import { getInitialLanguage } from "./webhid/commonReducer";
import AlerDialog from "./components/AlertDialog";

// 联系我们组件
function SettingsPage({}) {
  const [t, i18n] = useTranslation();
  function debugAction(code, content) {
    //button 1 2 3 4 .
    console.log("We click button", code, content);

    if (code === 4) {
      SendDataToHID(content);
    }
  }

  const tabNavData = [t("keyboard_setting"), t("ui_setting"), t("device_info")];

  const [activeTab, setActiveTab] = useState(0);
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: isLight ? "white" : "#212023",
        borderRadius: "8px",
        //border: "1px blue solid",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <TabNavigation
        data={tabNavData}
        onClickFunc={(index) => {
          setActiveTab(index);
        }}
        activeTab={activeTab}
      />
      <StatusBar showText={common.fatalLog} onClickProp={debugAction} />

      <div
        style={{
          width: "40%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "flex-start",
          fontSize: "0.75rem",
        }}
      >
        {activeTab === 0 && <KeyboardSettingTab />}
        {activeTab === 1 && <UISettingTab />}
        {activeTab === 2 && <DeviceInfoTab />}
      </div>
    </div>
  );
}

export default SettingsPage;

function KeyboardSettingTab({}) {
  const [t, i18n] = useTranslation();

  const sleepTimeArray = [
    ["5 " + t("time_miniute"), 5],
    ["10 " + t("time_miniute"), 10],
    ["30 " + t("time_miniute"), 30],
    [t("no_sleep"), 0],
  ];

  const dispatch = useDispatch();

  let common = useSelector((state) => state.persistent.common);
  const device = common.deviceName + "-Configure";
  common = common[device];

  const sleeptime = common.settings.sleeptime;

  const disabledKeys = common.settings.game_disable_list;

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "2vh",
          marginBottom: "0.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("sleep_time")} />
      </div>
      <div
        className="radiogroup"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        {sleepTimeArray.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              dispatch(settingSetRadioChoice("sleeptime", item[1]));
            }}
          >
            <input
              type="radio"
              onChange={() => {}}
              checked={sleeptime === item[1]}
            ></input>
            <label style={{ marginLeft: "0.5vh", marginRight: "4.5vh" }}>
              {item[0]}
            </label>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "2vh",
          width: "100%",
          marginBottom: "1.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("game_disable_keys")} />
      </div>
      <div
        className="disablegroup"
        style={{ display: "flex", rowGap: "1.8vh", flexWrap: "wrap" }}
      >
        {gameDisable.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              let newdata = [...disabledKeys];
              newdata[index] = !newdata[index];

              console.log("We disable key combination in game mode", newdata);
              dispatch(settingSetGameDisableList(newdata));
            }}
          >
            <input
              checked={disabledKeys[index]}
              onChange={() => {}}
              type="checkbox"
            ></input>
            <label style={{ marginLeft: "0.5vh", marginRight: "3vh" }}>
              {item[0]}
            </label>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "2vh",
          width: "100%",
          marginBottom: "0.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("restore_factory")} />
      </div>
      <button
        style={{
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#4368FF",
          color: "white",
          margin: "0.3vh 0.8vh 0.3vh 0",
          padding: "0.4vh 3.9vh",
          cursor: "pointer",
        }}
        onClick={() => {
          console.log("ROCK we reset to factory.");
          dispatch(userResetToFactoryAction());

          i18n.changeLanguage(getInitialLanguage());
          i18next.changeLanguage(getInitialLanguage());
        }}
      >
        {t("reset_at_once")}
      </button>
    </>
  );
}

function UISettingTab({}) {
  const [t, i18n] = useTranslation();
  const uiThemeArray = [t("light_theme"), t("dark_theme"), t("follow_system")];

  const dispatch = useDispatch();
  let common = useSelector((state) => state.persistent.common);
  const languageArray = common.appConfig.languageArray.slice(0, 2);

  const device = common.deviceName + "-Configure";
  common = common[device];

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "2vh",
          marginBottom: "0.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("scheme")} />
      </div>
      <div
        className="radiogroup"
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        {uiThemeArray.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              dispatch(settingSetRadioChoice("theme", index));
            }}
          >
            <input
              type="radio"
              onChange={() => {}}
              checked={common.settings.theme === index}
            ></input>
            <label style={{ marginLeft: "0.5vh", marginRight: "3vh" }}>
              {item}
            </label>
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "2vh",
          width: "100%",
          marginBottom: "0.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("language_setting")} />
      </div>
      <div
        className="disablegroup"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {languageArray.map((item, index) => (
          <div
            key={index}
            style={{ margin: "0.3vh 0", display: "flex", alignItems: "center" }}
            onClick={() => {
              dispatch(changeAppLanguage(item[2]));
              i18n.changeLanguage(item[2]);
              i18next.changeLanguage(item[2]);

              dispatch(settingSetRadioChoice("language", index));
            }}
          >
            <input
              style={{ marginRight: "1.5vh" }}
              checked={common.settings.language === index}
              onChange={() => {}}
              type="radio"
            ></input>
            <img
              style={{
                width: "10%",
              }}
              src={item[1]}
            ></img>

            <label style={{ margin: "1vh" }}>{item[0]}</label>
          </div>
        ))}
      </div>
      <span
        style={{
          textDecoration: "underline",
          cursor: "pointer",
          margin: "1.2vh 0.5vh",
        }}
        onClick={() => {
          dispatch(popupConfigureUIAction(true, -1));
        }}
      >
        {t("more_language_setting")}
      </span>
    </>
  );
}

function DeviceInfoTab({}) {
  const [t, i18n] = useTranslation();
  const connect = useSelector((state) => state.connect);
  const productName = connect.productName;

  let common = useSelector((state) => state.persistent.common);
  const isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];

  const dispatch = useDispatch();
  const firmwareVersion = common.settings.firmwareVersion;

  useEffect(() => {
    //Get Firmware Version.
    SendDataToHID("02 04");

    //SN
    //SendDataToHID("02 13");
  }, []);

  const [newVersionUpgrade, setNewVersionUpgrade] = useState("");
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  async function checkUpdate() {
    try {
      const response = await fetch(
        "https://akko-5087-keyboard-ota-cz.oss-cn-beijing.aliyuncs.com/akko-5087-keyboard-ota.json",
        //"https://rockzhang.com/akko-5087-keyboard-ota.json",
        {
          cache: "no-cache",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setNewVersionUpgrade(data.fw);

      // Log the versions being compared for debugging
      console.log("Current version:", firmwareVersion);
      console.log("Available version:", data.fw);

      var compare = require("node-version-compare");
      const comparison = compare(firmwareVersion, data.fw);
      console.log("Comparison result:", comparison);

      if (comparison >= 0) {
        // Changed from !== -1 to >= 0 for clarity
        console.log("Update no need.", firmwareVersion, data.fw);
        setIsLatestVersion(true);
        dispatch(blurMainPageAction(true));
        //dispatch(sendAlertHintsAction("Upgrade No needs", "dd"));
      } else {
        console.log("Update is essential.", firmwareVersion, data.fw);
        setIsLatestVersion(false);
        dispatch(blurMainPageAction(true));
        //dispatch(sendAlertHintsAction("Upgrade to version", "dd"));
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  let latestVersionButtons = [
    [
      t("dialog_btn_confirm"),
      "#276bF9",
      () => {
        dispatch(blurMainPageAction(false));
        setNewVersionUpgrade("");
      },
    ],
  ];

  let needUpgradeButtons = [
    [
      t("dialog_btn_cancel"),
      isLight ? "#D9D9D9" : "#4d4d4d",
      () => {
        dispatch(blurMainPageAction(false));
        setNewVersionUpgrade("");
      },
    ],
    [
      t("dialog_btn_confirm"),
      "#276bF9",
      () => {
        dispatch(blurMainPageAction(false));
        setNewVersionUpgrade("");
        // Trigger download
        const downloadUrl =
          "https://akko-5087-keyboard-ota-cz.oss-cn-beijing.aliyuncs.com/AKKO%205087%20DFU%20Tool.exe";
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "AKKO 5087 DFU Tool Install v1.0.exe";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    ],
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "2vh",
          marginBottom: "0.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("device_details")} />
      </div>

      {newVersionUpgrade.length > 0 && isLatestVersion && (
        <AlerDialog
          title={t("uptodateversion")}
          content={t("uptodateversioncontent")}
          buttons={latestVersionButtons}
        />
      )}

      {newVersionUpgrade.length > 0 && !isLatestVersion && (
        <AlerDialog
          title={t("newversion")}
          content={t("newversioncontent")}
          buttons={needUpgradeButtons}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "1vh" }}>{t("device_name") + ":"}</span>
          <span>{productName}</span>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "1vh" }}>
            {t("firmware_version") + ":"}
          </span>
          <span>{firmwareVersion}</span>
        </div>

        {/* <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: "1vh" }}>SN:</span>
          <span>{t("unknown")}</span>
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          marginTop: "2vh",
          marginBottom: "0.8vh",
          justifyContent: "flex-start",
        }}
      >
        <BlackTitle title={t("firmware_upgrade")} />
      </div>

      <span style={{ margin: "0.4vh 0 0.8vh 0" }}>{t("latest_version")}</span>

      <button
        style={{
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#4368FF",
          color: "white",
          margin: "0.3vh 0.8vh 0.3vh 0",
          padding: "0.4vh 3.9vh",
          cursor: "pointer",
        }}
        onClick={async () => {
          await checkUpdate();
        }}
      >
        {t("check_update")}
      </button>
    </>
  );
}

function BlackTitle({ title }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <span style={{ fontWeight: "bold" }}>{title}</span>
    </div>
  );
}
