import { useTranslation } from "react-i18next";
import { useState, useEffect, useRef } from "react";
import "./Sidebar.css";
import { useSelector, useDispatch } from "react-redux";
import getConnectedDevice from "./webhid/devices";
import { useNavigate } from "react-router-dom";
import {
  connectDeviceAction,
  connectedDeviceAction,
  userImportWholeConfig,
} from "./webhid/action";
import SeparatorLine from "./components/SeparatorLine";
import SendDataToHID from "./webhid/sendData";

// ... existing code ...
import packageJson from "../package.json"; // Adjust the path as necessary

const Sidebar = () => {
  const commitId = process.env.REACT_APP_COMMIT_ID;

  const { t, i18n } = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];
  const topMenuIndex = common ? common.topMenuIndex : 0;

  const connect = useSelector((state) => state.connect);
  const productName = connect.productName;

  console.log("ROCK we get the productName is", productName);
  const batteryInfo = common ? common.settings.batteryInfo : "";
  // console.log("We get battery", batteryInfo);

  const charging = batteryInfo === "bc";
  const batteryNum = charging ? 100 : parseInt(batteryInfo, 16);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const connectedDevices = connect.connectedDevices;

  const appVersion = " " + commitId;

  useEffect(() => {
    if (connect.isConnected) {
      // 电池信息。
      SendDataToHID("02 26");

      //定义设备监控逻辑;
      let i = 0;
      const monitorDevices = async () => {
        const device = await getConnectedDevice(productName);
        if (device === null) {
          dispatch(connectedDeviceAction(false, null));
        }
      };
      // 定时检查设备连接状态
      const interval = setInterval(() => {
        monitorDevices();
      }, 1000);
      // 清理定时器
      return () => {
        clearInterval(interval);
      };
    } else {
      navigate("/");
      setMenuSelected(0);
    }
  }, [connect.isConnected]);

  const menuItems = [
    t("sidebar_my_device"),
    t("sidebar_tools"),
    t("sidebar_frimware"),
  ];

  const menuIcons_Dark = [
    "/mydevicedark.png",
    "/toolsdark.png",
    "/firmwaredark.png",
  ];

  const configItems = [
    t("sidebar_conf_file"),
    t("sidebar_input_conf"),
    t("sidebar_quic_conf"),
    t("sidebar_race_conf"),
  ];

  const [menuSelected, setMenuSelected] = useState(-1);

  //productName 是当前连接的名字。
  useEffect(() => {
    if (!productName || productName.length === 0) {
      console.log("We refresh navigate to /");

      // clear sidebar menu items.
      dispatch(connectedDeviceAction(false, null));
      // navigate the url for MainStructure.
    }
  }, [productName, navigate]);

  const [configSelectedIndex, setConfigSelectIndex] = useState(-1);

  const [menuVisible, setMenuVisible] = useState([false, false, false, false]);
  const imgRef = useRef(Array.from({ length: 4 }, () => null));
  const menuRef = useRef(Array.from({ length: 4 }, () => null));

  const toggleMenu = (index) => {
    if (index === 0) return;

    setMenuVisible((prev) => {
      return prev.map((visible, i) => (i === index ? !visible : false));
    });
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      setMenuVisible([false, false, false, false]);
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const fileRef = useRef(null);

  const [currentMenuSelect, setCurrentMenuIndex] = useState(0);

  useEffect(() => {
    if (productName) {
      navigate("/index/keymap");
    }
  }, [productName]);

  if (productName && productName.length > 0) {
    return (
      <div
        className="sidebar"
        style={{
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: isLight ? "white" : "#171719",
          color: isLight ? "black" : "white",
        }}
      >
        <img
          style={{
            width: "50%",
            maxWidth: "80%",
            alignSelf: "center",
            margin: "3vh 0",
            cursor: "pointer",
          }}
          src="/logo.png"
          alt="DISHTEC"
          onClick={() => {}}
        />

        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              marginTop: "1vh",
              flexDirection: "column",
              lineHeight: 1.5,
            }}
          >
            {connectedDevices.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  borderRadius: "4px",
                  backgroundColor:
                    item === productName
                      ? isLight
                        ? "#ced8f8"
                        : "#293875"
                      : "#fefefe",

                  color: isLight ? "black" : "#EAEAEA",
                }}
                onClick={() => {
                  const pathNav = [
                    "/index/keymap",
                    "/index/light",
                    "/index/fasttrigger",
                    "/index/superkey",
                    "/index/settings",
                  ];

                  navigate(pathNav[topMenuIndex]);
                  dispatch(connectedDeviceAction(true, item));
                }}
              >
                <div
                  style={{
                    display: "flex",
                    color: "black",
                    flexDirection: "row",
                    justifyContent: "space-between",

                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    padding: "0.5vh 0",
                    margin: "0",
                  }}
                >
                  <span
                    style={{
                      color: isLight ? "black" : "#EAEAEA",
                      marginLeft: "15px",
                    }}
                  >
                    {item}
                  </span>
                  <img
                    src="/dots.png"
                    alt={"create devices"}
                    style={{
                      width: "7%",
                      marginRight: "15px",
                      display: "inline",
                      marginRight: "1vh",
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    color: "black",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px dotted black",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    padding: "0.5vh 0",
                    margin: "0",
                  }}
                >
                  <img
                    src={
                      batteryInfo === "bc"
                        ? "/charging.png"
                        : batteryNum >= 20
                        ? "/fullbattery2.png"
                        : "/lowbattery.png"
                    }
                    alt={"create devices"}
                    style={{
                      width: "7%",
                      marginLeft: "1.5vh",
                      display: "inline",
                    }}
                  />

                  <span style={{ color: isLight ? "black" : "#EAEAEA" }}>
                    {batteryNum + "%"}
                  </span>
                </div>
              </div>
            ))}

            <div
              style={{
                width: "100%",
                display: "flex",
                color: "#4368FF",
                flexDirection: "row",
                justifyContent: "center",
                border: "1px  #4368FF, solid",
                borderRadius: "4px",
                alignItems: "center",
                cursor: "pointer",
                padding: "0.5vh 0",
                margin: "2vh 0",
              }}
              onClick={() => {
                dispatch(connectedDeviceAction(false, null));
                navigate("/");
              }}
            >
              <span>{t("sidebar_device_list")}</span>
            </div>
          </div>

          <SeparatorLine></SeparatorLine>

          <div
            className="lowerpart"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2vh",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 1,
              width: "100%",
            }}
          >
            <input
              type="file"
              id="fileInput"
              ref={fileRef}
              accept=".json"
              style={{ display: "none" }}
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    try {
                      const json = JSON.parse(e.target.result);
                      let setFileName = event.target.files[0].name;

                      dispatch(userImportWholeConfig(json, currentMenuSelect));

                      console.log("fileName is", setFileName, json);
                      event.target.value = null;
                      fileRef.current.value = null;
                    } catch (error) {}
                  };
                  reader.readAsText(file);
                }
              }}
            />

            {configItems.map((item, index) => (
              <div
                key={index + 200}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "55%",
                  borderRadius: "3px",
                  alignItems: "center",
                  margin: "0.5vh auto",
                  padding: "0.5vh 4vh",
                  cursor: index === 0 ? "default" : "pointer",
                  color: isLight ? "black" : index === 0 ? "#666" : "#999",
                  backgroundColor:
                    index === configSelectedIndex
                      ? isLight
                        ? "#EEF0F2"
                        : "#666"
                      : isLight
                      ? "white"
                      : "#171719",
                }}
                onClick={() => {
                  if (index >= 1) {
                    setConfigSelectIndex(index);
                  }
                }}
              >
                <span
                  style={{
                    color: index === 0 ? "gray" : isLight ? "black" : "#EAEAEA",
                  }}
                >
                  {item}
                </span>

                <div style={{ padding: 0, margin: 0, position: "relative" }}>
                  <img
                    src="/dots.png"
                    ref={(el) => (imgRef.current[index] = el)}
                    onClick={(e) => {
                      toggleMenu(index);
                      if (index >= 1) {
                        setConfigSelectIndex(index);
                      }
                      e.stopPropagation();
                    }}
                    style={{
                      position: "relative",
                      width: "2.5vh",
                      height: "2.5vh",
                      display: "inline",
                      visibility: index === 0 ? "hidden" : "visible",
                    }}
                  />

                  {menuVisible[index] && (
                    <div
                      ref={(el) => (menuRef.current[index] = el)}
                      style={{
                        zIndex: 500,
                        position: "absolute",
                        display: "flex",
                        width: "9vh",
                        padding: "0",
                        color: "#E4F4EF",
                        borderRadius: "3px",
                        backgroundColor: "#252525",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "space-between",

                        top: imgRef.current[index]
                          ? imgRef.current[index].offsetHeight + 10 + "px"
                          : "0px",
                        left: "50%",
                        transform: "translate(-50%, 0)",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "0.7vh",
                          height: "0.7vh",
                          backgroundColor: "#252525",
                          bottom: "100%",
                          clipPath: "polygon(50% 25%, 100% 100%, 0% 100%)",
                        }}
                      ></div>
                      <div
                        className="hoverable-div"
                        style={{
                          margin: "0.4vh auto",
                          padding: "0.2vh 0",
                          width: "100%",
                          textAlign: "center",
                          //position: "relative",
                          zIndex: 501,
                          pointerEvents: "auto",
                          //backgroundColor: "transparent",
                        }}
                        onClick={(e) => {
                          //set;
                          let jsonString = JSON.stringify(common, null, 2);

                          // 创建 Blob 对象
                          const blob = new Blob([jsonString], {
                            type: "application/json",
                          });

                          // 创建一个临时的 <a> 标签用于下载
                          const url = URL.createObjectURL(blob);
                          const a = document.createElement("a");
                          a.href = url;

                          a.download =
                            productName +
                            " " +
                            generateDateTimeString() +
                            ".json";

                          // 触发下载
                          document.body.appendChild(a);

                          a.click();
                        }}
                      >
                        {t("sidebar_export_conf")}
                      </div>
                      <div
                        className="hoverable-div"
                        style={{
                          margin: "0.4vh auto",
                          padding: "0.2vh 0",
                          width: "100%",
                          textAlign: "center",
                          zIndex: 501,
                          pointerEvents: "auto",
                        }}
                        onClick={(e) => {
                          // console.log("We click menu", index);
                          setCurrentMenuIndex(index);
                          fileRef.current.click();
                        }}
                      >
                        {t("sidebar_import_conf")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div
          className="bottompart"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            marginLeft: "16vh",
            justifyContent: "flex-start",
            marginBottom: "2vh",
          }}
        >
          <span style={{ width: "100%" }}>
            {t("sidebar_version_info") + ":" + appVersion}
          </span>
          <span style={{ width: "100%" }}>
            {"Date: " + process.env.REACT_APP_COMMIT_DATE}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="sidebar"
        style={{
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: isLight ? "white" : "#171719",
          color: isLight ? "black" : "white",
        }}
      >
        <img
          style={{
            width: "50%",
            maxWidth: "80%",
            alignSelf: "center",
            margin: "3vh 0",
            cursor: "pointer",
          }}
          src="/logo.png"
          alt="DISHTEC"
          onClick={() => {}}
        />

        <div
          className="upperpart"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            flex: 1,
            justifyContent: "flex-start",
            marginTop: "1vh",
          }}
        >
          <MenuLine
            icon={menuIcons_Dark[0]}
            text={menuItems[0]}
            clickCallback={() => {
              navigate("/");
              setMenuSelected(0);
            }}
            select={menuSelected == 0}
          />

          <MenuLine
            icon={menuIcons_Dark[1]}
            text={menuItems[1]}
            clickCallback={() => {
              navigate("/tools");
              setMenuSelected(1);
            }}
            select={menuSelected == 1}
          />

          <MenuLine
            icon={menuIcons_Dark[2]}
            text={menuItems[2]}
            clickCallback={() => {
              navigate("/firmware");
              setMenuSelected(2);
            }}
            select={menuSelected == 2}
          />
        </div>

        <div
          className="upperpart"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            marginLeft: "16vh",
            justifyContent: "flex-start",
            marginBottom: "2vh",
          }}
        >
          <span style={{ width: "100%" }}>
            {t("sidebar_version_info") + ":" + appVersion}
          </span>
          <span style={{ width: "100%" }}>
            {"Date: " + process.env.REACT_APP_COMMIT_DATE}
          </span>
        </div>
      </div>
    );
  }
};

export default Sidebar;

function generateDateTimeString() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(now.getDate()).padStart(2, "0");

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function MenuLine({ icon, text, clickCallback, select }) {
  const common = useSelector((state) => state.persistent.common);
  const isLight = common.isLight;

  return (
    <div
      style={{
        cursor: "pointer",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: "2vh 0",
        justifyContent: "center",
        borderRadius: "5px",
        marginBottom: "4vh",
        backgroundColor: select
          ? isLight
            ? "#ced8f8"
            : "#28288e"
          : isLight
          ? "white"
          : "#333",
        // border: "1px blue solid",
        alignItems: "center",
      }}
      onClick={() => {
        clickCallback(text);
      }}
    >
      <img
        src={icon}
        alt={text}
        style={{
          width: "10%",

          display: "inline",
          marginRight: "1vh",
        }}
      />
      <span style={{ width: "30%" }}>{text}</span>
    </div>
  );
}
