import { useState } from "react";
import { useSelector } from "react-redux";

function StatusBar({ showText, onClickProp }) {
  const [userInput, setUserInput] = useState("");

  let common = useSelector((state) => state.persistent.common);
  const device = common.deviceName + "-Configure";
  common = common[device];
  //showText = common.keyboardBelowClickValue;
  return (
    <div
      className="status-bar"
      style={{
        position: "absolute",
        bottom: "0",
        left: "0%",
        width: "100%",
        height: "30px",
        backgroundColor: "#999",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        margin: "0, auto",
        justifyContent: "center",
        fontSize: "16px",
        opacity: "0.8",
      }}
    >
      <button
        style={{ marginRight: "15px" }}
        onClick={() => {
          onClickProp(1, userInput);
        }}
      >
        Action1
      </button>
      <button
        style={{ marginRight: "15px" }}
        onClick={() => {
          onClickProp(2, userInput);
        }}
      >
        Action2
      </button>
      <button
        style={{ marginRight: "15px" }}
        onClick={() => {
          onClickProp(3, userInput);
        }}
      >
        Action3
      </button>
      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onClickProp(4, userInput);
            setUserInput("");
          }
        }}
      />
      <button
        style={{ margin: "0 15px" }}
        onClick={() => {
          onClickProp(4, userInput);
          setUserInput("");
        }}
      >
        SendCommand
      </button>
      <p style={{ marginLeft: "15px", color: "red" }}> {showText} </p>
    </div>
  );
}

export default StatusBar;
