import { useState, useEffect, useCallback, useRef } from "react";
import TabNavigation from "./components/TabNavigation";
import ButtonUnit from "./components/ButtonUnit";
import StatusBar from "./StatusBar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllPopupAction,
  popupConfigureUIAction,
  sendAlertHintsAction,
  sendFatalLogAction,
  userClickKeyboardBelowAction,
  userStartRealDragAction,
} from "./webhid/action";

import ImageUnit from "./components/ImageUnit";
import MacroNameUnit from "./components/MacroNameUnit";
import SeparatorLine from "./components/SeparatorLine";
import {
  getShowTextByKeyCode,
  getUidByKeyCode,
  keyMapData,
} from "./webhid/KeyboardKeyData";
import { useTranslation } from "react-i18next";
import { convertIntToHexString } from "./webhid/constants";
import SendDataToHID from "./webhid/sendData";
import { saveBits } from "./SettingData";

// 联系我们组件
function KeyMapsPage() {
  const [t, i18n] = useTranslation();
  const tabNavData = [
    t("mapping_basic"),
    t("mapping_multimedia"),
    t("mapping_leighteffect"),
    t("mapping_compose"),
    t("mapping_mouse_btn"),
    t("mapping_macro"),
  ];

  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];
  const selectedKeyIds = common.mapPendingKey;

  const dispatch = useDispatch();
  const activeTab = common.buttonMap.bottomTabIndex;

  const calculateDistance = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: isLight ? "white" : "#252525",
        borderRadius: "8px",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
      onMouseMove={(e) => {
        const distance = calculateDistance(
          common.mouseDownX,
          common.mouseDownY,
          e.clientX,
          e.clientY
        );

        if (common.mouseDownX > 0 && distance > 20) {
          console.log(
            "Distance moved:",
            common.mouseDownX,
            common.mouseDownY,
            e.clientX,
            e.clientY,
            distance
          );
          dispatch(userStartRealDragAction());
        }
      }}
    >
      <TabNavigation
        data={tabNavData}
        onClickFunc={(index) => {}}
        activeTab={activeTab}
      />
      {/* Basic Keys. */}
      {activeTab === 0 && <KeyBoardComponent />}
      {/* Multimedia Keys. */}
      {activeTab === 1 && <MediaKeyList type="media" />}
      {activeTab === 2 && <MediaKeyList type="light" />}
      {activeTab === 3 && <ComposeButton pendingKey={selectedKeyIds} />}
      {activeTab === 4 && <MediaKeyList type="mouse" />}
      {activeTab === 5 && <MacroList />}
    </div>
  );
}

export default KeyMapsPage;

function ComposeButton({ pendingKey }) {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  let common = useSelector((state) => state.persistent.common);
  const device = common.deviceName + "-Configure";
  common = common[device];

  const dispatchedValue = common.userCombineKeys;

  let values = dispatchedValue ? dispatchedValue.split("+") : [null, null];
  let valueCount = values.length;

  const specialButtonCompistion = ["Ctrl", "Shift", "Alt", "Win"];

  let predefinedValue = [false, false, false, false];

  for (let i = 0; i < specialButtonCompistion.length; i++) {
    predefinedValue[i] = values.some((k) => k === specialButtonCompistion[i]);
  }

  console.log(
    "ROCK1 We Received dispatchedValue",
    predefinedValue,
    values[valueCount - 1]
  );

  const [bindingKey, setBindingKey] = useState(values[valueCount - 1]);
  const [bindingKeyCode, setBindingKeyCode] = useState("");
  const [keysChecked, setKeysChecked] = useState(predefinedValue);

  useEffect(() => {
    setBindingKey(values[valueCount - 1]);
    setBindingKeyCode("");
    setKeysChecked(predefinedValue);
  }, pendingKey);

  const recordEventFunc = useCallback((event) => {
    if (event instanceof KeyboardEvent) {
      console.log("KeyPress ", event.key, event.code, event); //use Code.

      let showText = getShowTextByKeyCode(event.code);

      if (showText) {
        const textSecs = showText.split("\\n");

        showText = textSecs.join(" | ");
        setBindingKey(showText);
        setBindingKeyCode(getUidByKeyCode(event.code));
      }
      return;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", recordEventFunc);
    return () => {
      window.removeEventListener("keydown", recordEventFunc);
    };
  }, []);

  return (
    <div
      className="buttonComposition"
      style={{
        margin: "5px auto 5px auto",
        display: "flex",
        width: "40%",
        height: "100%",
        fontSize: "0.75rem",
        //border: "1px red solid",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {specialButtonCompistion.map((item, index) => (
          <div
            key={index}
            style={{ margin: "2vh" }}
            onClick={(e) => {
              const updatedKeysChecked = [...keysChecked];
              updatedKeysChecked[index] = !updatedKeysChecked[index];
              setKeysChecked(updatedKeysChecked);
            }}
          >
            <input
              type="checkbox"
              id={`myCheckbox-${index}`}
              style={{ margin: "1vh" }}
              checked={keysChecked[index]}
              onChange={() => {
                const updatedKeysChecked = [...keysChecked];
                updatedKeysChecked[index] = !updatedKeysChecked[index];
                setKeysChecked(updatedKeysChecked);
              }}
            />
            <label for="myCheckbox">{item}</label>
          </div>
        ))}
      </div>

      <div style={{ width: "70%", marginBottom: "2vh" }}>
        <SeparatorLine />
      </div>

      <span>+</span>

      <div
        style={{
          width: "5vh",
          height: "5vh",
          border: "1px #cdcdcd solid",
          borderRadius: "5px",
          margin: "1.5vh auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>{bindingKey}</span>
      </div>
      <span>{t("mapping_please_select_key")}</span>

      <button
        style={{
          border: "none",
          borderRadius: "5px",
          backgroundColor: "#4368FF",
          color: "white",
          margin: "2.3vh 0.8vh 0.3vh 0",
          padding: "0.4vh 3.9vh",
          cursor: "pointer",
        }}
        onClick={() => {
          if (bindingKey.length > 0 && keysChecked.some((item) => item)) {
            let showContent = "";
            let orignalData = 0;
            keysChecked.forEach((checked, index) => {
              if (checked) {
                showContent += specialButtonCompistion[index];
                showContent += "+";

                orignalData = saveBits(orignalData, index, checked);
              }
            });

            let firstByteStr = convertIntToHexString(orignalData, 1);
            let secondByteStr = bindingKeyCode.split(" ")[1];

            showContent += bindingKey;
            console.log(
              "We output originalData is ",
              orignalData,
              orignalData.toString(2).padStart(8, "0"),
              firstByteStr + " " + secondByteStr,
              showContent
            );

            dispatch(
              userClickKeyboardBelowAction(
                "/combine.png",
                firstByteStr + " " + secondByteStr,
                showContent
              )
            );
          }
        }}
      >
        {t("mapping_apply")}
      </button>
    </div>
  );
}

function MacroList({}) {
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];

  const selectedKeyValue = common.keyboardBelowClickValue;
  const dispatch = useDispatch();

  const defaultMacros = common.macroMap.defaultMacros;

  useEffect(() => {
    SendDataToHID("0D"); // query macro buffer size.
  }, []);

  const [viewMacroClicked, setViewMacroClicked] = useState(false);

  return (
    <div
      style={{
        marginTop: "1vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            width: "4vh",
            height: "4vh",
            borderRadius: "4px",
            display: "flex",
            margin: "0.5vh",
            flexDirection: "column",
            fontSize: "0.8rem",
            padding: "2px",
            backgroundColor: viewMacroClicked
              ? "#0045d5"
              : isLight
              ? "#e1e1e1"
              : "#2E2E2F",
            border: "1px solid #0045d5",

            justifyContent: "center",
            alignItems: "center",
          }}
          onMouseDown={() => {
            setViewMacroClicked(true);
          }}
          onClick={(e) => {
            dispatch(popupConfigureUIAction(true, 0));
            setViewMacroClicked(false);
          }}
        >
          <img
            src="/viewmacro.png"
            style={{
              width: "80%",
              height: "80%",
              //borderRadius: "4px",
              //border: "1px dotted blue",
            }}
          ></img>
        </div>

        {defaultMacros.map((item, index) => {
          return (
            <MacroNameUnit
              width={2}
              height={2}
              text={item.name}
              uid={item.storeIndex}
              key={index}
              hasAction={item.actionList.length > 0}
              selected={selectedKeyValue === item.name}
              onClickEvent={(clicked) => {
                if (clicked) {
                  if (item.actionList.length > 0) {
                    dispatch(
                      userClickKeyboardBelowAction(item.name, item.storeIndex)
                    );
                  } else {
                    dispatch(
                      sendAlertHintsAction(t("dialog_macro_empty"), index)
                    );
                  }
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

function MediaKeyList({ type }) {
  let common = useSelector((state) => state.persistent.common);
  const device = common.deviceName + "-Configure";
  common = common[device];
  const selectedKeyUID = common.keyboardBelowClickUid;
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();

  const multiMediaKeyList = [
    [
      {
        icon: "/play_voice_gray.png",
        uid: "00 AF",
        keycode: "XX",
        text: t("multimedia_play"),
      },
      {
        icon: "/mute.png",
        uid: "00 A8",
        keycode: "XX",
        text: t("multimedia_mute"),
      },
      {
        icon: "/volume_down.png",
        uid: "00 AA",
        keycode: "XX",
        text: t("multimedia_volume_down"),
      },
      {
        icon: "/volume_up.png",
        uid: "00 A9",
        keycode: "XX",
        text: t("multimedia_volume_up"),
      },
      {
        icon: "/last_song.png",
        uid: "00 AC",
        keycode: "XX",
        text: t("multimedia_previous"),
      },
      {
        icon: "/pause.png",
        uid: "00 AE",
        keycode: "XX",
        text: t("multimedia_pause"),
      },
      {
        icon: "/next_song.png",
        uid: "00 AB",
        keycode: "XX",
        text: t("multimedia_next"),
      },
      {
        icon: "/play_stop.png",
        uid: "78 02",
        keycode: "XX",
        text: t("multimedia_stop"),
      }, //00 AD first one?
      {
        icon: "/screen_up.png",
        uid: "78 27",
        keycode: "XX",
        text: t("multimedia_bright_plus"),
      },
      {
        icon: "/screen_down.png",
        uid: "78 28",
        keycode: "XX",
        text: t("multimedia_bright_minus"),
      },
    ],
    [
      {
        icon: "/open_dir.png",
        uid: "08 08",
        keycode: "XX",
        text: t("multimedia_opendir"),
      },
      {
        icon: "/refresh.png",
        uid: "00 3E",
        keycode: "XX",
        text: t("multimedia_refresh"),
      },
      {
        icon: "/open_file.png",
        uid: "01 12",
        keycode: "XX",
        text: t("multimedia_openfile"),
      },
      {
        icon: "/paste.png",
        uid: "01 19",
        keycode: "XX",
        text: t("multimedia_paste"),
      }, //ctrl V
      {
        icon: "/execution.png",
        uid: "08 15",
        keycode: "XX",
        text: t("multimedia_run"),
      },
      {
        icon: "/search.png",
        uid: "00 3C",
        keycode: "XX",
        text: t("multimedia_search"),
      }, // F3
      {
        icon: "/mycomputer.png",
        uid: "00 B3",
        keycode: "XX", //Win + E　　or 00 B3
        text: t("multimedia_mycomputer"),
      },
      {
        icon: "/calculator.png",
        uid: "00 B2", //01 92
        keycode: "XX",
        text: t("multimedia_calculator"),
      },
      {
        icon: "/lockcomputer.png",
        uid: "08 0F", // Win + L
        keycode: "XX",
        text: t("multimedia_lockpc"),
      },
      {
        icon: "/showdesk.png",
        uid: "08 07", // Win + D
        keycode: "XX",
        text: t("multimedia_showdesk"),
      },
      {
        icon: "/closewin.png",
        uid: "04 3D", // Alt + F4
        keycode: "XX",
        text: t("multimedia_closewin"),
      },
      {
        icon: "/revoke.png",
        uid: "01 1d",
        keycode: "XX",
        text: t("multimedia_revoke"),
      },
      {
        icon: "/new.png",
        uid: "01 11",
        keycode: "XX",
        text: t("multimedia_newfile"),
      },
      {
        icon: "/selectall.png",
        uid: "01 04",
        keycode: "XX",
        text: t("multimedia_selectall"),
      },
      {
        icon: "/copy.png",
        uid: "01 06",
        keycode: "XX",
        text: t("multimedia_copy"),
      },
      {
        icon: "/cut.png",
        uid: "01 1b",
        keycode: "XX",
        text: t("multimedia_cut"),
      },
    ],
  ];

  const lightnessKeyList = [
    [
      {
        icon: "/light_left.png",
        uid: "78 22",
        keycode: "XX",
        text: t("map_mode_minus"),
      },
      {
        icon: "/light_right.png",
        uid: "78 21",
        keycode: "XX",
        text: t("map_mode_plus"),
      },
      {
        icon: "/bright_up.png",
        uid: "78 27",
        keycode: "XX",
        text: t("map_bright_plus"),
      },
      {
        icon: "/bright_down.png",
        uid: "78 28",
        keycode: "XX",
        text: t("map_bright_minus"),
      },
      {
        icon: "/speed_up.png",
        uid: "78 29",
        keycode: "XX",
        text: t("map_speed_plus"),
      },
      {
        icon: "/speed_down.png",
        uid: "78 2A",
        keycode: "XX",
        text: t("map_speed_minus"),
      },
      {
        icon: "/power.png",
        uid: "78 02",
        keycode: "XX",
        text: t("map_powser_switch"),
      },
      {
        icon: "/color_plus.png",
        uid: "78 20",
        keycode: "XX",
        text: t("map_switch_color"),
      },
    ],
  ];

  const mouseButtonList = [
    [
      {
        icon: "/leftkey.png",
        uid: "00 D1",
        keycode: "XX",
        text: t("click_leftbutton"),
      },

      {
        icon: "/middlekey.png",
        uid: "00 D3",
        keycode: "XX",
        text: t("wheel_button"),
      },
      {
        icon: "/rightkey.png",
        uid: "00 D2",
        keycode: "XX",
        text: t("click_rightbutton"),
      },
      {
        icon: "/backwards.png",
        uid: "04 08",
        keycode: "XX",
        text: t("backward_button"),
      },
      {
        icon: "/forwards.png",
        uid: "04 10",
        keycode: "XX",
        text: t("forward_button"),
      },
      // { icon: "/dpi.png", uid: "0", keycode: "XX", text: t("dpi_button") },
      // {
      //   icon: "/doubleclick.png",
      //   uid: "04 01 00 00 02",
      //   keycode: "XX",
      //   text: t("double_click"),
      // },
    ],
  ];

  function getKeyListByType(type) {
    if (type === "media") {
      return multiMediaKeyList;
    } else if (type === "light") {
      return lightnessKeyList;
    } else if (type === "mouse") {
      return mouseButtonList;
    }
  }

  return (
    <div
      style={{
        marginTop: "1vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {getKeyListByType(type).map((line, lineNo) => (
        <div
          key={`line-${lineNo}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {line.map((item, index) => {
            return (
              <ImageUnit
                width={2}
                height={2}
                icon={item.icon}
                uid={item.uid}
                key={index}
                text={item.text}
                selected={selectedKeyUID === item.uid}
                onClickEvent={(clicked) => {
                  console.log("We save key number is ", lineNo * 100 + index);
                  if (clicked) {
                    dispatch(userClickKeyboardBelowAction(item.icon, item.uid));
                  }
                }}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}

function KeyBoardComponent() {
  const [t, i18n] = useTranslation();
  let common = useSelector((state) => state.persistent.common);
  let isLight = common.isLight;
  const device = common.deviceName + "-Configure";
  common = common[device];
  const selectedKeyValue = common.keyboardBelowClickValue;
  const selectedKeyUid = common.keyboardBelowClickUid;
  const nextWidthRef = useRef(1);

  const dispatch = useDispatch();

  nextWidthRef.current = 1;

  const recordEventFunc = useCallback((event) => {
    if (event instanceof KeyboardEvent) {
      //event.preventDefault();
      console.log("KeyPress ", event.key, event.code, event); //use Code.

      let showText = getShowTextByKeyCode(event.code);
      let uid = getUidByKeyCode(event.code);
      //convert event.code to show content.
      dispatch(userClickKeyboardBelowAction(showText, uid));
      dispatch(sendFatalLogAction(`key: ${event.key}, code: ${event.code}`));

      //
      if (event.code === "Escape") {
        dispatch(clearAllPopupAction());
      }
      return;
    }
  }, []);

  useEffect(() => {
    //query firmware version.

    window.addEventListener("keydown", recordEventFunc);
    return () => {
      window.removeEventListener("keydown", recordEventFunc);
    };
  }, []);

  return (
    <div style={{ marginTop: "1vh", display: "flex", flexDirection: "column" }}>
      <span
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "0.65rem",
          color: isLight ? "white" : "#999",
        }}
      >
        {t("mapping_hints")}
      </span>
      {keyMapData.map((line, lineNo) => (
        <div key={`line-${lineNo}`} style={{ display: "flex" }}>
          {line.map((item, index) => {
            if (item.text) {
              const next = nextWidthRef.current;
              nextWidthRef.current = 1;

              return (
                <ButtonUnit
                  width={next}
                  height={1}
                  uid={item.uid}
                  key={`${lineNo}-${index}-${item.text}`}
                  text={item.text}
                  // text={"" + item.uid}
                  selected={selectedKeyUid === item.uid}
                  onClickEvent={(clicked) => {
                    console.log("We save key number is ", lineNo * 100 + index);
                    if (clicked) {
                      dispatch(
                        userClickKeyboardBelowAction(item.text, item.uid)
                      );
                    }
                  }}
                />
              );
            } else {
              if (item.x) {
                return (
                  <ButtonUnit
                    width={item.x}
                    height={1}
                    key={`${lineNo}-${index}-${item.x}`}
                    text=""
                    selected={false}
                    onClickEvent={() => {}}
                  />
                );
              }

              if (item.w) {
                nextWidthRef.current = item.w;
              } else {
                nextWidthRef.current = 1;
              }

              return <div key={`empty-${lineNo}-${index}`}></div>;
            }
          })}
        </div>
      ))}
    </div>
  );
}
